import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'home',
        component: () => import('@/views/HomePage.vue')
    },
    {
        path: '/cmps',
        name: 'cmps',
        component: () => import('@/components/cmpsPage.vue')
    },
    {
        path: '/switch',
        name: 'login1',
        component: () => import('@/views/old/LoginSwitch.vue')
    },
    {
        path: '/modern',
        name: 'loginModern',
        component: () => import('@/views/old/LoginModern.vue')
    },
    {
        path: '/water',
        name: 'loginWater',
        component: () => import('@/views/old/LoginWater.vue')
    },
    {
        path: '/work',
        name: 'loginWork',
        component: () => import('@/views/old/LoginWork.vue')
    },
    {
        path: '/test',
        name: 'test',
        component: () => import('@/views/test.vue')
    },
    {
        path: '/test2',
        name: 'test2',
        component: () => import('@/views/test2.vue')
    },
    {
        path: '/personal',
        name: 'personal',
        component: () => import('@/components/personal.vue')
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/components/login.vue')
    },
    {
        path: '/header',
        name: 'header',
        component: () => import('@/components/header.vue')
    },
    {
        path: '/setting',
        name: 'setting',
        component: () => import('@/components/setting.vue')
    },
    {
        path: '/list',
        name: 'list',
        component: () => import('@/components/cmpsList.vue')
    },
    {
        path: '/home',
        name: 'home2',
        component: () => import('@/components/home.vue')
    },
    {
        path: '/case',
        name: 'case',
        component: () => import('@/components/caseShow.vue')
    },
    {
        path: '/sharecode',
        name: 'sharecode',
        component: () => import('@/makevideomodule/sharecode.vue')
    }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

export default router
